<template>
    <div>
        <h4>Styrelsen</h4>
        <b-table small striped hover :items="styr" :fields="fields"></b-table>
        <h4>Instruktörer</h4>
        <b-table small striped hover :items="inst" :fields="fields"></b-table>
        <h4>Kommitteordf.</h4>
        <b-table small striped hover :items="kordf" :fields="fields"></b-table>
    </div>
</template>

<script>
 import { db } from '@/firebaseConfig.js'
 import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'

 export default {
     async mounted() {
         try {
             const q = query(collection(db, 'members'),
                             where('functions', 'array-contains-any', ['styr', 'inst', 'kordf']))
             this.unsubscribe = onSnapshot(q, async (querySnapshot) => {
                 const ms = []
                 querySnapshot.forEach(async (memberDoc) => {
                     ms.push({ id: memberDoc.id, ...memberDoc.data() })
                 })
                 this.members = ms
             })
         } catch (error) {
             console.log('error', error)
         }
     },
     destroyed() {
         this.unsubscribe()
     },
     computed: {
         styr() {
             return this.members
                        .filter(c => c.functions.includes('styr'))
                        .map(m => { return { name: m.firstName + ' ' + m.lastName, ...this.contacts.get(m.id), ...m } })
                        .sort((m1, m2) => m1.firstName.localeCompare(m2.firstName, 'sv'))
         },
         inst() {
             return this.members
                        .filter(c => c.functions.includes('inst'))
                        .map(m => { return { name: m.firstName + ' ' + m.lastName, ...this.contacts.get(m.id), ...m } })
                        .sort((m1, m2) => m1.firstName.localeCompare(m2.firstName, 'sv'))
         },
         kordf() {
             return this.members
                        .filter(c => c.functions.includes('kordf'))
                        .map(m => { return { name: m.firstName + ' ' + m.lastName, ...this.contacts.get(m.id), ...m } })
                        .sort((m1, m2) => m1.firstName.localeCompare(m2.firstName, 'sv'))
         }
     },
     data() {
         return {
             fields: [
                 { key: 'name', label: 'Namn' },
                 { key: 'email', label: 'Epost' },
                 { key: 'phoneNumber', label: 'Telefon' },
             ],
             members: [],
             contacts: new Map(),
             unsubscribe: null
         }
     },
     watch: {
         members: async function(newMembers) {
             const promises = newMembers.map(async m => {
                 const docRef = doc(db, `members/${m.id}/private`, 'contacts')
                 const docSnap = await getDoc(docRef)
                 if (docSnap.exists()) {
                     return [m.id, docSnap.data()]
                 }
                 return [m.id, null]
             })
             this.contacts = new Map(await Promise.all(promises))
         }
     }
 }
</script>
